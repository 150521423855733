import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  sample_projectWidth: {
    width: pxToRem(1174),
    height: pxToRem(665),
    margin: "0 auto",
    padding: `${pxToRem(9)} ${pxToRem(25)}`,
    position: "relative",
  },
  margin_top_116: {
    marginTop: pxToRem(80),
  },
  left_previous: {
    flexGrow: 1,
  },
  right_previous: {
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  slider_width: {
    width: pxToRem(900),
    height: pxToRem(400),
  },
  slider: {
    width: pxToRem(900),
    margin: "0 auto",
    marginTop: pxToRem(20),
    "& .slick-next": {
      "&:before": {
        content: "url(assets/images/wing-vertical.png)",
        width: pxToRem(14),
        height: pxToRem(107),
        transform: "rotate(0deg)  translateY(-64px)",
        display: " inline-block",
        marginTop: pxToRem(-16),
      },
    },
    "& .slick-prev": {
      "&:before": {
        content: "url(assets/images/wing-vertical.png)",
        width: pxToRem(14),
        height: pxToRem(107),
        transform: "rotate(180deg) translateY(64px)",
        display: " inline-block",
      },
    },
    "& .slick-dots": {
      bottom: pxToRem(-32),
    },
    "& .slick-initialized .slick-slide": {
      marginBottom: pxToRem(25),
    },
    "& .slick-dots li button:before": {
      fontSize: pxToRem(13),
      color: color.primary_palette.franklin_purple,
    },
  },
  design_img: {
    margin: "0 auto",
    marginTop: pxToRem(46),
  },
  text_center: {
    textAlign: "center",
    marginTop: pxToRem(33),
  },
  text1: {
    flexGrow: 1,
    width: pxToRem(481),
  },
  text2: {
    flexGrow: 1,
    width: pxToRem(481),
  },
  marginSides: {
    margin: `${pxToRem(8)} ${pxToRem(7)}`,
  },
  font_four: {
    fontSize: pxToRem(14),
  },
  buttons_align: {
    marginTop: pxToRem(10),
    marginBottom: pxToRem(6),
    marginLeft: pxToRem(32),
  },
  horizontal_DB: {
    width: pxToRem(300),
    marginLeft: pxToRem(436),
  },
  imageDesc: {
    width: pxToRem(1008),
    margin: `${pxToRem(16)} auto ${pxToRem(44)} auto`,
    paddingBottom: pxToRem(40),
  },
  imageDesc2: {
    width: pxToRem(1008),
    margin: `${pxToRem(0)} auto ${pxToRem(0)} auto`,
  },
  imageWidth: {
    width: pxToRem(900),
    height: pxToRem(400),
    objectFit: "contain",
    marginBottom: pxToRem(12),
  },
  clickBtnStyle: {
    cursor: "pointer",
  },
  preview_btn: {
    height: pxToRem(35),
    marginRight: pxToRem(20),
    borderRadius: pxToRem(22),
    marginLeft: pxToRem(32),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiButton-label": {
      color: color.primary_palette.franklin_purple,
      fontSize: pxToRem(16.3),
      ...tradework_theme.typography.styles.avenir_sb,
      position: "relative !important",
    },
  },
  titleStyle: {
    textAlign: "center",
  },
  editModeBlock: {
    width: pxToRem(174),
    position: "relative",
  },
  pencilAlign: {
    position: "absolute",
    right: pxToRem(-24),
    top: pxToRem(24),
    cursor: "pointer",
  },
  width_50: {
    width: "50%",
  },
  flexEnd: {
    justifyContent: "flex-end",
    display: "flex",
  },
  editBlockStyle: {
    textAlign: "center",
    borderBottom: `solid ${pxToRem(0.5)} ${color.primary_palette.black}`,
  },
  custom_edit_btn_styles: {
    height: pxToRem(22),
    width: pxToRem(83),
    borderRadius: pxToRem(4),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.black}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(5),
    marginBottom: pxToRem(10),
    "& .MuiButton-label": {
      color: color.primary_palette.black,
      fontSize: pxToRem(12),
      ...tradework_theme.typography.styles.avenir_sb,
      textTransform: "capitalize",
    },
    "&:hover": {
      border: `solid ${pxToRem(1.6)} ${color.button_yellow}`,
      backgroundColor: `${color.primary_palette.white} !important`,
    },
  },
  portfolio_overView_btn: {
    height: pxToRem(34),
    width: pxToRem(250),
    borderRadius: pxToRem(4),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.black}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(18),
    marginLeft: pxToRem(120),
    marginBottom: pxToRem(10),
    "& .MuiButton-label": {
      color: color.primary_palette.black,
      fontSize: pxToRem(20),
      ...tradework_theme.typography.styles.avenir_sb,
      textTransform: "capitalize",
    },
    "&:hover": {
      border: `solid ${pxToRem(1.6)} ${color.button_yellow}`,
      backgroundColor: `${color.primary_palette.white} !important`,
    },
  },
  custom_public_btn_styles: {
    height: pxToRem(22),
    width: pxToRem(83),
    borderRadius: pxToRem(4),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.black}`,
    backgroundColor: color.primary_palette.white,
    marginTop: pxToRem(5),
    marginBottom: pxToRem(10),
    "& .MuiButton-label": {
      color: color.primary_palette.black,
      fontSize: pxToRem(12),
      ...tradework_theme.typography.styles.avenir_sb,
      textTransform: "capitalize",
    },
    "&:hover": {
      border: `solid ${pxToRem(1.6)} ${color.primary_palette.highlight_purple}`,
      backgroundColor: `${color.primary_palette.white} !important`,
    },
  },
  current_btn_style: {
    height: pxToRem(22),
    width: pxToRem(83),
    borderRadius: pxToRem(4),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.black}`,
    backgroundColor: color.button_purple,
    marginTop: pxToRem(5),
    marginBottom: pxToRem(10),
    "& .MuiButton-label": {
      color: color.primary_palette.black,
      fontSize: pxToRem(12),
      ...tradework_theme.typography.styles.avenir_sb,
      textTransform: "capitalize",
    },
    "&:hover": {
      border: `solid ${pxToRem(1.6)} ${color.primary_palette.black}`,
      backgroundColor: `${color.button_purple} !important`,
    },
  },
  marginRight7: {
    marginRight: pxToRem(7),
  },
  markerAlign: {
    cursor: "pointer",
  },
  // publicAlign: {
  //   marginLeft: "auto",
  //   marginRight: pxToRem(32),
  // },
  hoverIconBlock: {
    "& .hover_icon": {
      cursor: "pointer",
    },
    "&:hover": {
      "& .hover_icon": {
        "& path": {
          fill: color.form_colors.sucess_color,
        },
      },
    },
  },
  betaStampIcon: {
    height: pxToRem(100),
    position: "absolute",
    right: pxToRem(34),
    top: pxToRem(140),
    cursor: "pointer",
  },
  current_btn_yellow_style: {
    height: pxToRem(22),
    width: pxToRem(83),
    borderRadius: pxToRem(4),
    border: `solid ${pxToRem(1.6)} ${color.primary_palette.black}`,
    backgroundColor: color.button_yellow,
    marginTop: pxToRem(5),
    marginBottom: pxToRem(10),
    "& .MuiButton-label": {
      color: color.primary_palette.black,
      fontSize: pxToRem(12),
      ...tradework_theme.typography.styles.avenir_sb,
      textTransform: "capitalize",
    },
    "&:hover": {
      border: `solid ${pxToRem(1.6)} ${color.button_yellow}`,
      backgroundColor: `${color.button_yellow} !important`,
    },
  },
});

export default styles;
