import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  navBar: {
    width: "20%",
  },
  portfolio_content: {
    width: "100%",
    display: "block",
  },
  marginLeft4: {
    marginLeft: pxToRem(4),
  },
  portfolio_spacings: {
    paddingLeft: pxToRem(30),
  },
  marginTop6: {
    marginTop: pxToRem(6),
  },
  addportfoliosBtn: {
    color: `${color.primary_palette.franklin_purple} !important`,
    fontSize: pxToRem(24.7),
    textTransform: "capitalize",
    paddingRight: pxToRem(20),
  },
  saveFlipIcon: {
    position: "relative",
    top: pxToRem(4),
    left: pxToRem(4),
    cursor: "pointer",
  },
  cursor_pointer: {
    cursor: "pointer",
  },
  flex_grow: {
    flexGrow: 1,
    // width: "50%",
  },
  width_100: {
    width: "100%",
  },
  txtRight: {
    textAlign: "right",
  },
  pencil_icon: {
    paddingLeft: pxToRem(10),
    width: pxToRem(13),
    cursor: "pointer",
  },
  trash_icon: {
    fontSize: pxToRem(12),
    paddingLeft: pxToRem(10),
    cursor: "pointer",
    "& path": {
      fill: color.secondary_palette.grays.background_gray,
    },
  },
  width_40: {
    width: "40%",
    display: "block",
  },
  width_38: {
    width: "38%",
    display: "block",
  },
  width_42: {
    width: "42%",
    display: "block",
  },
  width_20: {
    width: "20%",
    display: "block",
  },
  market_width: {
    width: pxToRem(155),
    borderRadius: pxToRem(2),
    height: pxToRem(20),
    marginRight: pxToRem(8),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
    "& p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  addBtn: {
    width: pxToRem(59),
    height: pxToRem(20),
    borderRadius: pxToRem(5),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.secondary_palette.grays.btn_gray,
    color: `${color.primary_palette.free_purple} !important`,
    fontSize: pxToRem(15),
    ...tradework_theme.typography.styles.avenir_sb,
    padding: 0,
  },
  market_width_des: {
    width: pxToRem(207),
    borderRadius: pxToRem(2),
    height: pxToRem(20),
    marginRight: pxToRem(21),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
    "& p": {
      ...tradework_theme.typography.styles.avenir_sb,
      fontSize: pxToRem(15),
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  cross_icon: {
    width: pxToRem(8),
    height: pxToRem(10),
    paddingLeft: pxToRem(21),
  },
  which_office: {
    width: pxToRem(340),
    overflow: "hidden",
    borderRadius: pxToRem(2),
    height: pxToRem(20),
    marginRight: pxToRem(21),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
    "& p": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
  fontBold: {
    fontWeight: "bold",
  },
  padding_left_7: {
    paddingLeft: pxToRem(7),
  },
  padding_bottom_36: {
    paddingBottom: pxToRem(36),
  },
  padding_top_37: {
    paddingTop: pxToRem(37),
  },
  padding_top_8: {
    paddingTop: pxToRem(8),
  },
  portfolio_radio: {
    paddingTop: pxToRem(26),
  },
  alignCenter: {
    alignSelf: "center",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  img_width: {
    width: pxToRem(300),
    border: `solid ${pxToRem(3)} ${color.primary_palette.black}`,
    margin: "0 auto",
    display: "block",
    padding: pxToRem(5),
  },
  company_images: {
    width: "100%",
    height: pxToRem(210),
  },
  company_logo: {
    width: pxToRem(65),
    height: pxToRem(65),
    position: "absolute",
    bottom: pxToRem(3),
    left: pxToRem(19),
    borderRadius: "50%",
  },
  width_30: {
    width: "30%",
    textAlign: "center",
    position: "relative",
  },
  width_70: {
    width: "70%",
    textAlign: "center",
    alignSelf: "center",
  },
  txtDecoration: {
    borderBottom: `solid ${pxToRem(0.5)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
  },
  padding_top_3: {
    paddingTop: pxToRem(3),
  },
  portfolio_img_innerwrap: {
    border: `solid ${pxToRem(0.5)} ${color.primary_palette.black}`,
    width: "100%",
    height: "100%",
    position: "relative",
  },
  marginLeft36: {
    marginLeft: pxToRem(20),
  },
  viewBtn: {
    width: pxToRem(318),
    height: pxToRem(30),
    backgroundColor: color.primary_palette.white,
    color: `${color.primary_palette.franklin_purple} !important`,
    fontSize: pxToRem(15),
    ...tradework_theme.typography.styles.gillsans_sb,
    margin: "0 auto",
    display: "block",
    padding: 0,
    marginTop: pxToRem(6),
    cursor: "pointer",
    textAlign: "right",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  marginLeft20: {
    marginLeft: pxToRem(20),
  },
  marginLeft120: {
    marginLeft: pxToRem(120),
  },
  saveBtn: {
    width: pxToRem(162),
    height: pxToRem(30),
    borderRadius: pxToRem(15),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    color: `${color.primary_palette.franklin_purple} !important`,
    fontSize: pxToRem(15),
    ...tradework_theme.typography.styles.gillsans_sb,
    display: "inline-block",
    padding: 0,
    marginTop: pxToRem(24),
    "& .MuiButton-label": {
      position: "relative !important",
    },
  },
  txtCenter: {
    textAlign: "center",
  },
  percentage_border: {
    width: pxToRem(186),
    height: pxToRem(56),
    borderRadius: pxToRem(5),
    border: `solid ${pxToRem(0.5)} ${color.primary_palette.franklin_purple}`,
    paddingTop: pxToRem(11),
    paddingBottom: pxToRem(9),
  },
  width_20_flex: {
    width: "20%",
    display: "flex",
    justifyContent: "flex-end",
  },
  greencheck: {
    width: pxToRem(17),
    height: pxToRem(17),
    paddingRight: pxToRem(6),
  },
  redMinus: {
    width: pxToRem(15),
    height: pxToRem(3),
    paddingRight: pxToRem(6),
  },
  tally_text: {
    ...tradework_theme.typography.styles.avenir_roman,
    fontSize: pxToRem(14),
    color: color.clammy_pearl,
  },
  tally_spacing: {
    paddingLeft: pxToRem(23),
    paddingTop: pxToRem(7),
  },
  tally_list: { display: "flex", alignItems: "center" },
  list_spacing: {
    paddingTop: pxToRem(21),
  },
  borderBottom_spacing: {
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    paddingBottom: pxToRem(50),
    marginTop: pxToRem(10),
  },
  table_data: {
    width: "100%",
    marginTop: pxToRem(25),
    "& th": {
      ...tradework_theme.typography.styles.avenir_bold,
      fontWeight: 900,
      fontSize: pxToRem(15),
      textAlign: "left",
      color: color.primary_palette.free_purple,
      width: pxToRem(130),
    },
    "& td": {
      ...tradework_theme.typography.styles.avenir_roman,
      fontSize: pxToRem(15),
      color: color.primary_palette.black,
      width: pxToRem(130),
    },
  },
  text_transform: {
    textTransform: "uppercase",
  },
  table_dropdown: {
    width: "100%",
    height: pxToRem(22),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
  },
  table_dropdown_width: {
    width: pxToRem(146),
    height: pxToRem(23),
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
  },
  table_spacing: {
    paddingBottom: pxToRem(25),
  },
  body_spacing: {
    borderBottom: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
    borderCollapse: "separate",
    borderSpacing: pxToRem(4),
    boxShadow: `${pxToRem(1)} ${pxToRem(1)} ${pxToRem(0)} ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
  },
  headBorder: {
    boxShadow: `${pxToRem(1)} ${pxToRem(1)} ${pxToRem(0)} ${pxToRem(1)} ${
      color.primary_palette.franklin_purple
    }`,
  },
  padding_space_table: {
    paddingTop: pxToRem(25),
    // paddingBottom: pxToRem(25)
  },
  block: {
    display: "block",
    marginLeft: pxToRem(20),
  },
  padding_top: {
    paddingTop: pxToRem(20),
  },
  table_span: {
    ...tradework_theme.typography.styles.avenir_roman,
    fontSize: pxToRem(15),
    color: color.primary_palette.black,
    textTransform: "capitalize",
  },
  border_gray: {
    borderTop: `solid ${pxToRem(1)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
  },
  coloredCrossStyle: {
    width: pxToRem(14),
    marginLeft: pxToRem(4),
    position: "relative",
    top: pxToRem(6),
    cursor: "pointer",
    "& path": {
      fill: color.secondary_palette.grays.shadow_gray,
    },
  },
  text_ellipsis: {
    width: pxToRem(140),
    margin: "0 auto",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    paddingBottom: pxToRem(5),
    borderBottom: `solid ${pxToRem(0.5)} ${
      color.secondary_palette.grays.shadow_gray
    }`,
  },
  avenir_heavy: {
    ...tradework_theme.typography.styles.avenir_sb,
  },
  avenir_light: {
    ...tradework_theme.typography.styles.avenir_light,
    fontSize: pxToRem(14),
  },
  list_dropdown: {
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.black,
    fontSize: pxToRem(15),
    fontWeight: "bold",
  },
  categoryAlign: {
    marginLeft: pxToRem(5),
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  customScrollHeight: {
    minHeight: `${pxToRem(150)} !important`,
    "& li": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
    },
  },
  padding_rigth_20: {
    paddingRight: pxToRem(20),
  },
  viewReciptStyle: {
    cursor: "pointer",
    textDecoration: "underline",
    fontSize: pxToRem(14),
    color: color.secondary_palette.grays.shadow_gray,
    ...tradework_theme.typography.styles.avenir_sb,
  },
  cardDetails: {
    textAlign: "right",
    borderRadius: pxToRem(2),
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.social_gray}`,
    padding: `${pxToRem(5)} ${pxToRem(11)} ${pxToRem(4)} ${pxToRem(2)}`,
    position: "relative",
  },
  enableStyle: {
    cursor: "pointer",
  },
  masterAlign: {
    position: "absolute",
    left: pxToRem(8),
    width: pxToRem(27),
    height: pxToRem(20),
  },
  city_spacing: {
    marginBottom: pxToRem(10),
    marginTop: pxToRem(7),
    textTransform: "uppercase",
  },
  add_portfolio_btn: {
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(26),
      verticalAlign: "middle",
      paddingRight: pxToRem(7),
      "& path": {
        "&:nth-child(1)": {
          fill: color.secondary_palette.grays.background_gray,
        },
      },
    },
    "&:hover": {
      "& .MuiSvgIcon-root": {
        "& path": {
          "&:nth-child(1)": {
            fill: color.primary_palette.franklin_purple,
          },
        },
      },
    },
  },
  text_field: {
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.NeutraText,
      color: color.primary_palette.black,
    },
  },
  messageBoxStyle: {
    width: "370px",
  },
  copyAlign: {
    margin: "0 6px",
    cursor: "pointer",
  },
  txtBold: {
    fontWeight: "bold",
  },
  padding_left_57: {
    paddingLeft: pxToRem(12),
  },
  toggleDots: {
    "& .uncheck_dot": {
      top: `${pxToRem(-11)} !important`,
    },
    "& .checked_dot": {
      top: `${pxToRem(-11)} !important`,
    },
  },
  txtCenter: {
    textAlign: "center",
  },
  colorPurple: {
    color: color.primary_palette.highlight_purple,
  },
  customScrollHeightCategory: {
    minHeight: `${pxToRem(330)} !important`,
    "& li": {
      overflow: "hidden",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
    },
  },
  textCapital: {
    textTransform: "uppercase",
  },
  width462: {
    width: pxToRem(462),
  },
  portfolioPublicInfo: {
    width: pxToRem(486),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    borderRadius: pxToRem(6),
    padding: `${pxToRem(7)} ${pxToRem(15)} ${pxToRem(6)}`,
    display: "flex",
    marginTop: pxToRem(4),
    alignItems: "center",
    "& .hover_icon": {
      cursor: "pointer",
    },
    "&:hover": {
      "& .hover_icon": {
        "& path": {
          fill: color.form_colors.sucess_color,
        },
      },
    },
  },
  hoverGreen: {
    "&:hover": {
      color: color.form_colors.sucess_word_color,
    },
  },
  hoverGray: {
    "&:hover": {
      opacity: 0.5,
      color: color.primary_palette.black,
    },
  },
});

export default styles;
