import { SettingsApi } from "../api";
import { SettingsTypes } from "../actionTypes";
import { ApiActions } from ".";

export default {
  getCompanyDataByUser: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.getCompaniesByUser();
        dispatch({
          type: SettingsTypes.COMPANY_DATA_BY_USER,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getCompanyBasicsData: (id) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.getCompanyBasicsData(id);
        dispatch({
          type: SettingsTypes.GET_COMPANY_BASICS_DATA,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getPortfoliosByLocation: (id) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.getPortfoliosByLocation(id);
        dispatch({
          type: SettingsTypes.PORTFOLIOS_BY_LOCATION,
          payload: res.data.data.portfoliosWithMarkets,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getCompanyPortfolioData: (id) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.getCompanyPortfolioData(id);
        dispatch({
          type: SettingsTypes.GET_COMPANY_PORTFOLIO_DATA,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  savePortfolioDataObj: (data) => {
    return async (dispatch) => {
      dispatch({
        type: SettingsTypes.SAVED_PORTFOLIO_OBJECT,
        payload: data,
      });
    };
  },
  saveSelectedPortfolioData: (data) => {
    return async (dispatch) => {
      dispatch({
        type: SettingsTypes.SAVED_SELECTED_PORTFOLIO,
        payload: data,
      });
    };
  },
  isSinglePortfolioSave: (data) => {
    return async (dispatch) => {
      dispatch({
        type: SettingsTypes.PORTFOLIO_SAVE,
        payload: data,
      });
    };
  },
  isOpenMarketModal: (data) => {
    return async (dispatch) => {
      dispatch({
        type: SettingsTypes.MARKET_MODAL_OPEN,
        payload: data,
      });
    };
  },
  getCompanyLocationsData: (id) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.getCompanyLocationsData(id);
        dispatch({
          type: SettingsTypes.GET_COMPANY_LOCATIONS_DATA,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  updateCompanyPortfolioData: (id, data, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.updatePortfolioData(id, data);
        ApiActions.success(dispatch);
        cb && cb(res.data);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  updateOfficeLocationsData: (id, data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.updateOfficeLocationData(id, data);
        ApiActions.success(dispatch);
        callback(res.data);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  addDupePortfolio: (data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.addDupePortfolio(data);
        callback(res.data);
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  deleteCompanyAdmin: (companyId, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.deleteCompanyAdmin(companyId);
        ApiActions.success(dispatch);
        callback(res.data);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  generateInvoice: (data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.generateInvoice(data);
        ApiActions.success(dispatch);
        callback(res.data);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  deletePortfolio: (data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.deletePortfolio(data);
        ApiActions.success(dispatch);
        callback(res.data);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  deleteOfficeLocation: (data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.deleteOfficeLocation(data);
        ApiActions.success(dispatch);
        callback(res.data);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  portfolioAutoRenew: (data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.autoRenew(data);
        ApiActions.success(dispatch);
        callback(res.data);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  deleteBusinessMarketCategory: (id, data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.deleteBusinessMarketCategory(id, data);
        ApiActions.success(dispatch);
        callback(res.data);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  sendFeedback: (data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.sendFeedback(data);
        ApiActions.success(dispatch);
        callback(res.data);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  getCompanySelectedLocationsData: (id, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.getCompanyLocationsData(id);
        cb && cb(res.data.data);
        dispatch({
          type: SettingsTypes.GET_COMPANY_LOCATIONS_DATA,
          payload: res.data.data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  sendEmailGenerator: (data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.sendEmailGenerator(data);
        ApiActions.success(dispatch);
        callback(res.data);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  updateTempStatus: (data, callback) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.updateTempStatus(data);
        ApiActions.success(dispatch);
        callback(res.data);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  fetchAllEmailGenerators: () => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await SettingsApi.fetchAllEmailGenerators();
        const {
          data: { data },
        } = res;
        dispatch({
          type: SettingsTypes.GET_EMAIL_GENERATORS,
          payload: data,
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
};
