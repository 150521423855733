import api from ".";
import { URI } from "../../constants";

export default {
  getCompaniesByUser: () => {
    return api.get(URI.COMPANY_BY_USER);
  },
  getCompanyBasicsData: (id) => {
    return api.get(`${URI.COMPANY_BASICS_DATA}${id}`);
  },
  getPortfoliosByLocation: (id) => {
    return api.post(`${URI.PORTFOLIOS_BY_LOCATION}${id}`);
  },
  getCompanyPortfolioData: (id) => {
    return api.get(`${URI.COMPANY_PORTFOLIOS_DATA}${id}`);
  },
  getCompanyLocationsData: (id) => {
    return api.get(`${URI.COMPANY_LOCATIONS_DATA}${id}`);
  },
  updatePortfolioData: (id, data) => {
    return api.post(`${URI.UPDATE_PORTFOLIO_DATA}${id}`, data);
  },
  updateOfficeLocationData: (id, data) => {
    return api.post(`${URI.UPDATE_OFFICE_LOCATIONS}${id}`, data);
  },
  addDupePortfolio: (data) => {
    return api.post(`${URI.PORTFOLIO_DUPE}`, data);
  },
  deleteCompanyAdmin: (companyId) => {
    return api.get(`${URI.DELETE_COMPANY_ADMIN}${companyId}`);
  },
  generateInvoice: (data) => {
    return api.post(`${URI.GENERATE_INVOICE}`, data);
  },
  deletePortfolio: (data) => {
    return api.post(`${URI.DELETE_PORTFOLIO}`, data);
  },
  deleteOfficeLocation: (data) => {
    return api.post(`${URI.PORTFOLIO_OFFICE_LOCATION_UPDATE}`, data);
  },
  autoRenew: (data) => {
    return api.post(`${URI.TRANSACTION_RENEW}`, data);
  },
  deleteBusinessMarketCategory: (id, data) => {
    return api.post(`${URI.DELETE_BUSINESS_MARKET_CATEGORY}${id}`, data);
  },
  sendFeedback: (data) => {
    return api.post(`${URI.SEND_FEEDBACK}`, data);
  },
  sendEmailGenerator: (data) => {
    return api.post(`${URI.EMAIL_GENERATOR}`, data);
  },
  updateTempStatus: (data) => {
    return api.post(`${URI.UPDATE_TEMP_STATUS}`, data);
  },
  fetchAllEmailGenerators: () => {
    return api.get(URI.GET_ALL_EMAIL_GENERATORS);
  },
};
