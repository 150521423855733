import React from "react";

import { color } from "../../utilities/themes";
import InvoiceStyles from "./styles/invoiceStyles";
import { Text, Row } from "../common/ui_kit";
import strings from "../../utilities/strings";
import CustomButton from "../navigations/custom_buttons";

function Invoice(props) {
  const { classes } = props;

  return (
    <>
      <Row className={classes.margin_top_40}>
        <div className={classes.invoice_width}>
          <Row>
            <div className={classes.width_30}>
              <img
                src="/assets/images/FR.png"
                alt="FR"
                className={classes.fr_icon}
              />
              <div className={classes.padding_top_60}>
                <div className={classes.padding_bottom_30}>
                  <Text
                    size={15}
                    color={color.primary_palette.black}
                    family="avenir_sb"
                  >
                    AUGUST 1, 2020
                    <br />
                    Nº 13397
                  </Text>
                </div>
                <div className={classes.padding_bottom_30}>
                  <Text
                    size={15}
                    color={color.secondary_palette.grays.medium_gray}
                    family="avenir_sb"
                  >
                    {strings.invoice.title.billing_address}
                  </Text>
                  <Text
                    size={15}
                    color={color.primary_palette.black}
                    family="avenir_sb"
                  >
                    Ken Tate Architect <br />
                    433 North Columbia Street <br />
                    Covington LA 70443
                  </Text>
                </div>
                <div className={classes.padding_bottom_30}>
                  <Text
                    size={15}
                    color={color.secondary_palette.grays.medium_gray}
                    family="avenir_sb"
                  >
                    {strings.invoice.title.subcription}
                  </Text>
                  <Text
                    size={15}
                    color={color.primary_palette.black}
                    family="avenir_sb"
                  >
                    August 1, 2019 to July 31, 2020
                  </Text>
                  <Text
                    size={15}
                    color={color.primary_palette.black}
                    family="avenir_sb"
                  >
                    {strings.invoice.title.membership}
                  </Text>
                </div>
                <div className={classes.padding_top_225}>
                  <Text
                    size={15}
                    color={color.secondary_palette.grays.medium_gray}
                    family="avenir_sb"
                  >
                    {strings.invoice.title.report}
                  </Text>
                  <Text
                    size={15}
                    color={color.primary_palette.black}
                    family="avenir_sb"
                  >
                    336 West 23rd Street <br />
                    New York, NY 10011 <br />
                    PHL 866.960.9100 <br />
                    office@franklin.works
                  </Text>
                </div>
              </div>
            </div>
            <div className={classes.width_70}>
              <div className={classes.pos_rel}>
                {/* <div
                  className={`${classes.circle} ${classes.circle1_position}`}
                ></div>
                <div
                  className={`${classes.circle} ${classes.circle2_position}`}
                ></div> */}
                <div className={classes.invoice_data}>
                  <Text
                    size={25}
                    color={color.primary_palette.black}
                    family="gillsans_r"
                    className={`${classes.txtCenter} ${classes.padding_bottom_30} ${classes.padding_top_36}`}
                  >
                    <span className={classes.text_decoration}>
                      {strings.invoice.title.invoice}
                    </span>
                  </Text>
                  <div className={classes.padding_20}>
                    <table className={classes.table}>
                      <tr>
                        <th className={classes.border_bottom}>
                          {strings.invoice.title.item}
                        </th>
                        <th
                          className={`${classes.border_bottom} ${classes.txtRight} ${classes.padding_left_20}`}
                        >
                          {strings.invoice.title.description}
                        </th>
                        {/* <th className={classes.border_bottom}>
                          {strings.invoice.title.qty}
                        </th> */}
                        {/* <th className={classes.border_bottom}>
                          {strings.invoice.title.unit_price}
                        </th> */}
                        <th
                          className={`${classes.border_bottom} ${classes.txtRight}`}
                        >
                          {strings.invoice.title.total}
                        </th>
                      </tr>
                      <tr>
                        <td>FRP6/SOFLO</td>
                        <td
                          className={`${classes.txtRight} ${classes.padding_left_20}`}
                        >
                          Annual Portfolio <br /> Architects
                        </td>
                        {/* <td>1</td>
                        <td>$600.00</td> */}
                        <td className={`${classes.txtRight}`}>$540.00</td>
                      </tr>
                    </table>
                    <div
                      className={`${classes.txtRight} ${classes.padding_top_10}`}
                    >
                      <Text
                        size={15}
                        color={color.primary_palette.black}
                        family="avenir_sb"
                      >
                        <span className={classes.border_bottom}>
                          Pandemic Discount 50%{" "}
                          <span className={classes.padding_left_20}>
                            ($300)
                          </span>
                        </span>
                      </Text>
                      <Text
                        size={15}
                        color={color.primary_palette.black}
                        family="avenir_sb"
                        className={classes.padding_top_5}
                      >
                        Subscription{" "}
                        <span className={classes.padding_left_20}>$300</span>
                      </Text>
                    </div>
                    <div
                      className={`${classes.txtRight}  ${classes.padding_top_20}`}
                    >
                      {/* <Text
                        size={15}
                        color={color.primary_palette.black}
                        family="avenir_bold"
                      >
                        {" "}
                        Your first 6 months are Free!
                      </Text> */}
                      <Text
                        size={13}
                        color={color.primary_palette.black}
                        family="avenir_light"
                      >
                        {" "}
                        $300 will be due on February 1, 2022
                      </Text>
                    </div>
                    <div className={classes.total_cal}>
                      <Text
                        size={14}
                        color={color.primary_palette.franklin_purple}
                        family="avenir_bold"
                        className={classes.txtCapital}
                      >
                        AMOUNT DUE TODAY:{" "}
                        <span
                          className={`${classes.padding_left_20} ${classes.txtBlack}`}
                        >
                          $ 1,140.00
                        </span>
                      </Text>
                    </div>
                    <Row>
                      <div className={classes.paid_green}>
                        <img
                          src="/assets/images/paid_stamp.png"
                          className={classes.paid_stamp}
                          alt=""
                        />
                        <div>
                          <Text
                            size={20}
                            family="avenir_sb"
                            color={color.primary_palette.christmas_red}
                            className={classes.rotate_data}
                          >
                            07-AUG-19
                          </Text>
                        </div>
                      </div>
                      <div className={classes.paid_red}>
                        <img
                          src="/assets/images/Amount_Due_Stamp.png"
                          className={classes.amount_due}
                          alt=""
                        />
                      </div>
                    </Row>
                    <div className={classes.txtCenter}>
                      <div className={`${classes.thank_you}`}>
                        <span className={classes.border}></span>
                        <Text
                          size={25}
                          color={color.primary_palette.black}
                          family="gillsans_r"
                          className={`${classes.txtCenter} `}
                        >
                          {strings.invoice.title.thank_you}
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  className={`${classes.circle} ${classes.circle3_position}`}
                ></div>
                <div
                  className={`${classes.circle} ${classes.circle4_position}`}
                ></div> */}
              </div>
            </div>
          </Row>
          {/* <iframe
            src="https://trablobdev.blob.core.windows.net/tra-con-use-dev/invoices/invoice_1609911008723-old.pdf"
            width="800px"
            height="1000px"
            style={{ border: "none" }}
          /> */}
        </div>
        <div style={{ marginTop: "50px" }}>
          <div>
            <CustomButton className={classes.invoiceBtn}>
              {strings.invoice.title.pdf}
            </CustomButton>
          </div>
          <div>
            <CustomButton className={classes.invoiceBtn}>
              {strings.invoice.title.print}
            </CustomButton>
          </div>
        </div>
      </Row>
    </>
  );
}

export default InvoiceStyles(Invoice);
