const strings = {
  general: {
    titles: {},
    messages: {},
    errors: {},
  },
  image_text: {
    titles: {},
    alt_text: {
      cart_icon: "cart icon",
      happy_arrow: "Happy Arrow",
    },
    errors: {},
  },
  success_modal: {
    titles: {
      welcome: "welcome",
      pursue_your_dream_job: "Pursue your dream job with TradeWorks",
      begin: "let's begin",
    },
    messages: {},
    errors: {},
  },
  join_us: {
    titles: {
      tradeWorks: "Trade Works",
      firstName: "First Name",
      lastName: "Last Name",
      email_address: "Email Address",
      password: "Password",
      phone_number: "Phone Number",
      i_am: "I'm a:",
      company: "Company",
      individual: "Individual",
      join: "JOIN",
      account_exists: "Already have a Franklin Report account?",
      sign_in: "Sign In",
      sign_up_fr: "By signing up, you agree to FR's",
      terms_services: "Terms of Service",
      profile_name: "Profile Name",
      popover_description:
        "This doesn’t have to be your legal name but what you choose to be called in the job market.",
    },
    messages: {
      option_login: "Optional for faster Sign In later",
      password_rule: "8 characters. 1 Upper. 1 number or symbol",
      eight_chars: "8+ characters. ",
      one_letter: "Uppercase. ",
      number_symbol: "Number.",
      success_message: "Welcome!. Pursue your dream job with TradeWorks.",
      login_message: "8 + characters. Uppercase. Number.",
    },
    errors: {
      firstName_required: "First Name is required",
      firstName_min_max: "Minimum 1 Character or Max 35 Characters",
      firstName_max: "Minimum 1 Character or Max 35 Characters",
      lastName_required: "Last Name is required",
      lastName_min_max: "Minimum 1 Character or Max 35 Characters",
      lastName_max: "Minimum 1 Character or Max 35 Characters",
      email_address_required: "Email Address is required",
      invalid_email_address: "Please enter valid email address",
      password_required: "Password is required",
      password_max: "Password cannot be more than 20 characters",
      invalid_phone_number: "Invalid Phone Number",
    },
  },

  login: {
    titles: {
      forgot_your: "Forgot your",
      password: "password",
      or: "or",
      credentials: "Credentials",
      email: "email",
      not_member: "Not a member?",
      dont_have: "Not a member?",
      join_here: "Join Here",
      email_phone_number: "Email or Phone Number",
      email_phone_username: "Email, Phone or Username",
      valid_user: "Enter a valid Email",
    },
    errors: {
      email_phone_required: "Email or Phone Number is required",
      invalid_email_phone: "Please enter valid email or phone number",
      email_not_found: "Couldn't find that email, Try again!",
      password_dosent_match: "Password does not match, Try again!",
      match_not_found: "Couldn't find that match, Try again!",
    },
  },
  header: {
    titles: {
      hire: "HIRE",
      view_portfolios: "VIEW PORTFOLIOS",
      write_review: "Write a Review",
      submit_company: "Submit your Company",
      add_portfolio: "Add new portfolio",
      userName: "HELLO THERE",
      joinus: "JOIN US",
      signin: "SIGN IN",
    },
    messages: {},
    errors: {},
  },
  build: {
    titles: {
      build_title: "BUILD YOUR PORTFOLIO",
      build_des: "This is what your portfolio ",
      will_look: " will look like when we’re done",
      portfolio: "This portfolio will be  ",
      portfolio_free: "FREE ",
      portfolio_con: " for six months for all new subscribers.",
      portfolio_new:
        "Current subscribers will receive a free upgrade to these new enhanced portfolios.",
      current_franklin:
        "Hoping to help the design community fire back up, by catching those",
      current_franklin_1:
        "homenesters who are contemplating home improvements right now.",
      build_yours: "Let’s Build Yours!",
      profile_cover: "Profile Photo",
      cover_photo: "Cover Image",
      company_phil: "Company Philosophy",
      company_snapshot: "Company Snapshot",
      business_card: "Business Card",
      vignettes: "Vignettes",
      unliited_projects: "Unlimited Projects",
      on_summary: " (6 on Summary Page)",
      products: "Products & Publications",
      instagram: "Social Media Links",
      future_rate_card: "Future rate card",
      see_rate_card: "See Rate Card",
      see_all: "See Details",
      instagram_settings: "Instagram Settings",
      another_portfolio: "Great! Let’s Build another Portfolio",
      work_portfolio: "Great! Let’s work on your Portfolios",
      other_companies_1: "This would be for",
      other_companies:
        "If it is for one of your other companies, please click on that business in the top right corner dropdown",
      pick_location: "2. Pick office to be displayed on this portfolio",
      build_new: "Build a new portfolio",
      take_back: "OR, Take me back to an existing portfolio",
      services_showcased:
        "3. What services are being showcased in this portfolio, and for what markets?",
      portfolio_name: "4. Name of new portfofio:",
      dupe_portfolio: "1. Would you like to start with a dupe of one of ",
      dupe_port: "’s other portfolios?",
      start_new: "No, start new",
      start_with: "Start with",
      please_coordinate: "5. Please coordinate this creation with",
      lets_go: "LET'S GO!",
      add_another_office: "Add Another Office",
      primary_company_email: "This is the primary company contact info",
    },
  },
  landing_page: {
    titles: {
      log_in: "Sign In",
      join_us: "JOIN US",
      find_best_candidates: "FIND THE VERY BEST CANDIDATES",
      architecture_candidates: "Architecture Candidates",
      interior_design: "Interior Design Prospects",
      plumbing_heros: "Plumbing Heros",
      contractor_counsel: "Contractor Counsel",
      welcome: "WELCOME BACK",
      connect_with_community: "CONNECT WITH THE TRADE COMMUNITY",
      get_found: "GET FOUND",
      job_title: "JOB TITLE, COMPANY OR KEYWORDS",
      highlight_apprenticeships: "Highlight Apprenticeships",
      show_skills: "Show the world your skills",
      find_talent: "FIND TALENT",
      skills_jobs: "SKILLS, JOB TITLE OR KEYWORDS",
      appropriate_candidates: "Ping the most appropiate candidates",
      post_resume: "Post your resume",
      post_a_job: "Post a Job",
      footer_text: "2020 ® Tradeworks",
      hiring_companies: "HIRING COMPANIES",
      architectual_firms: "Architectual Firms",
      interior_design_firms: "Interior Design Firms",
      plumbing_outfits: "Plumbing Outfits",
      pursue_your_dream_job: "PURSUE YOUR DREAM JOB",
      architecture_positions: "Architecture Positions",
      interior_design_careers: "Interior Design Careers",
      plumbing_gigs: "Plumbing Gigs",
      contractor_openings: "Contractor Openings",
      placeholder_location: "city, state, zip",
      feedback_logo_text: "Beta Testin Feedback Logo",
    },
    messages: {},
    errors: {},
  },
  business_card: {
    titles: {
      company_name: "Company Name",
      company_add1: "Company Address 1",
      company_add2: "Company Address 2",
      state: "City, State, Zip Code",
      email: "Company Email",
      website: "Company Web Address",
      phone: "Phone Number",
      welcome: "Welcome to the Franklin Report community",
      lets: "Let’s start with your Business Card",
      company_current: "Company currently in FR",
      new_company: "New Company",
      already_in_fr: "Check here if you are already in the Franklin Report",
      company_des:
        "Even if you already have a portfolio with the Franklin Report, please confirm your public information below, and edit if appropriate.",
      general_info: "This is the general company contact info ",
      portfolio_display: "Where would you like this portfolio displayed?",
      add: "+ Add",
      you: "This is you",
      name: "Name:",
      mail: "Email:",
      continue: "CONTINUE",
      save_error:
        "Please fill the required fields or hit your back button to exit",
      new_portfolio: "MY NEW PORTFOLIO",
      new_portfolio_field: "Name this portfolio:",
      office_nick: "Office Nickname:",
      new_portfolio_placeholder: "New England Neoclassical Highlights",
      new_nick_placeholder: "Tribeca Office",
      card_des: "This is the general company contact info ",
      new_port_name: "NEW PORTFOLIO NAME",
      add_another_card: "Add another office for ",
      office_nickname: "Office Nickname",
      displayed_where: "DisplayED wHere?",
      services_offered: "Services Offered?",
      add_office: "Add Office",
      your_business_card: "• YOUR BUSINESS CARD •",
      your_company: "YOU ARE EDITING YOUR",
      your_company_location: "LOCATION",
      update: "UPDATE",
      city: "City",
      st: "ST",
      zip: "Zip",
      add_new_office: "Or Add A New Office",
      assign: "Or Assign an existing company address to this portfolio",
      assign_des:
        "Portfolios in displayed in multiple markets may have multiple company addresses, that you can choose in your OUR PORTFOLIOS settings",
    },
  },
  add_image: {
    titles: {
      profile_img: "• Profile Images •",
      company_headshot: "Upload a company headshot or team photo",
      company_headshot_des: "Highly recommended for best customer response",
      size_170: "Best size: 170 px by 170px ",
      company_logo: "Upload company logo",
      mandatory_info: "Not mandatory, but great for company branding",
      mandatory_des:
        "The default will be your company name in the top box as shown",
      size_300: "Best size: 300px by 300px ",
      cover_photo: "Upload Main Cover Photo",
      cover_photo_info: "Will give your portfolio warmth, presence and dignity",
      size_1024: "Best size: 1024 px by 900 px ",
      profile_picture: "• PERSONAL HEADSHOT •",
      drag_img: "Drag the image to desired position.",
      drag_zoom: "Move the zoom handle to change photo size.",
      profile_usage:
        "Your profile picture will be used in many places: some are square and some circular, which is why we give you both guides above.",
      chooseimg: "Choose NeW Image",
      save_continue: "Save & Continue",
      company_logo_title: "• Company Logo •",
      company_logo_subtitle:
        "This Company Logo will populate all your portfolios.",
      company_logo_headshot: "• Company HeadShot •",
      cover_image: "• COVER IMAGE •",
      cover_subtext: "Look your best: use an image 1024+ pixel wide",
      chosse_bg: "Choose excess background color :",
    },
  },
  elevator_pitch: {
    titles: {
      elevator_pitch: "• COMPANY BLURB •",
      elevator_pitch_des: "Tell us what distinguishes your company",
      company_snapshop: "Company Snapshot",
      company_snapshop_placeholder: "Modern, sinuous architecture with warmth",
      company_philosophy: "Company Philosophy/Areas of Expertise",
      save: "Save",
      Concept1: "Concept 1",
      Concept2: "Concept 2",
      placeholder1:
        "What sets your company apart from others in the field? We recommend 300-500 words, to look best",
      placeholder2:
        "Where do you especially shine? Unique style, industry skills, breadth of knowledge, clientele type, geographic presence…",
      desc_placeholder:
        "What sets your company apart from others in the field?",
    },
  },
  add_project: {
    titles: {
      add_project: "ADD NEW  PROJECT",
      save_draft: "Save Progress",
      my_grid: "MY IMAGE GRID",
      project_summary: "PROJECT SUMMARY",
      please_call: "Please call the Franklin Report to",
      enable_feature: "enable this feature",
      contact_num: "866.960.9100",
      automated_feature: "This will be an automatic function soon",
      about_project: "ABOUT PROJECT",
      project_title: "Project Title",
      project_titlePlaceholder: "Contemporary Fifth Avenue Apartment",
      project_subtitle: "Project Subtitle",
      style_monkier: "Project Subtitle: ",
      subtitle_sugg: "2-4 words best",
      project_subtitlePlaceholder:
        "Lightfilled Radiance, Joyful Beach Retreat, Family Fun",
      project_location: "Project Location",
      project_locationPlaceholder: "Park Ave, The Hamptons, Orange County",
      project_description: "Project Description",
      style: "Style",
      proj_images: "PROJECT IMAGES",
      proj_images_sub:
        "Increase search engine optimization, fill as much info as possible for each image",
      venue: "Clientele",
      setting: "Setting/Vibe",
      ballpark_cost: "Ballpark Cost",
      hashtags:
        "#Hashtags will significantly increase your traffic to this project; recommended 4 - 6",
      hashtags_placeholder: "#FifthAvenue, #Classical, #Red, #Tropical, etc.",
      description: "Description",
      hashtags1: "Hashtags ",
      materials: "Materials/Techniques",
      features: "Features",
      main_colors: "Color Palette",
      featured_trade: "Featured Trade",
      room: "Room",
      add_next: "Add Next Project Image",
      editor_placeholder:
        "Tell us more about this project: location, client preferences, special effects, and challenges surmounted. 5 to 8 sentences recommended.",
      Cover: "Cover?",
      textareaPlaceholder:
        "Stylistic inspiration? Favorite element? Historical reference?",
      hashtags_placeholder1: "Add #tags separated by commas",
      add_project_des: "Please add project images below. One is required",
      back: "GO BACK",
      save_continue: "Save & Continue",
      preview: "Preview This Project",
      preview_entire: "Preview Entire Portfolio",
      main_portfolio: "Main Portfolio",
      back_to_overview: "Back To Main Portfolio",
      this_project: "This Project",
      this_portfolio: "This Portfolio",
      back_to_project: "BACK TO EDIT PROJECT",
      back_to_portfolio: "Back to Main Portfolio Editing",
      save: "Save",
      update: "Update",
      turret: "SOUTHAMPTON BEACH TURRET",
      previous_project: "< Previous Project",
      next_project: "Next Project >",
      slider_des:
        "Nam a rutrum velit, vel bibendum libero. Fusce dolor risus, iaculis at maximus a, auctor eu nibh. Proin sem orci, eleifend ac tempus vitae, dignissim vel purus. Nullam vel dictum sapien. ",
      design_insights: "DESIGN INSIGHTS",
      // Interior: "Tropical House by the Sea...",
      confirmation: "ARE YOU SURE YOU WANT TO DELETE THIS IMAGE?",
      confirmation_project: "ARE YOU SURE YOU WANT TO DELETE THIS PROJECT?",
      confirmation_portfolio: "ARE YOU SURE YOU WANT TO DELETE THIS PORTFOLIO?",
      action: "You cannot undo this action",
      undo: "You may not undo this action",
      delete_item: "DELETE PROJECT",
      delete_item_image: "DELETE IMAGE",
      yes_in: "YES, I’M SURE",
      on_it: "I’m On It",
      save_edits: "SAVE EDITS",
      project_sample: "Project Sample",
      edit_project_images: "PROJECT IMAGES",
      edit_project_images_des:
        "Increase search engine optimization, fill as much info as possible for each image",
      your_project: "• YOUR PROJECTS •",
      your_project_des1:
        "Your first six projects will be displayed in your Main Profile Page",
      your_project_des2:
        "Additional projects may be displayed in your Featured Projects page",
      project_img_des: "Tropical House by the Sea",
      flagship_project: "FLAGSHIP PROJECTS",
      additional_project: "ADDITIONAL PROJECTS",
      add_new_project: "Add New Project",
      your_project_cover_image: "Your Project Cover Image",
      step1_des1: "*Choose how many images will be displayed in this project.",
      step1_des2: "Then we can help you crop them to proper shapes and sizes.",
      step2_desc:
        "Ok! Let’s begin by uploading your first image. We will be filling this grid together.",
      step2_desc_line:
        "We recommend a minimum size of 1000 pixels wide for best quality.",
      step1: "STEP 1: ",
      step2: "STEP 2: ",
      step2_des2:
        "Ok! Let’s begin by uploading your first image. We will be filling this grid together.",
      image_grid: "My Image Grid",
      num_grid:
        "If you change the number of images, you will most likely need to upload and crop your images again to fit the new grid. Proceed with new grid?",
      please: "YES PLEASE",
      choose_grid:
        "You currently have a grid that includes 6 images. If change your grid shape, you will most likely need to recrop your images",
      stick: "Stick with 6 images",
      choose_grid_with: "Chose new grid with",
      image: "images",
      understood: "UNDERSTOOD",
      project_dashboard: "• PROJECT DASHBOARD •",
      edit_mode: "EDIT MODE",
      public_previews: "PUBLIC PREVIEWS",
    },
  },
  portfolio_userView: {
    titles: {
      portfolio_visibility: "Portfolio Visibility:",
      you: "Only You",
      public: "Public",
      overview: "OVERVIEW",
      invite_clients: "Invite Clients to view",
      share: "Share",
      invite_clients_view: "INVITE CLIENTS TO VIEW",
      invite_friends_clients_view: "INVITE FRIENDS & CLIENTS TO VIEW",
      displayed_where_is: "Where is this displayed",
      displayed_where_is_sub:
        "Where and what services do you offer in this portfolio?",
      edit_mode: "Edit View",
      publicView: "Public View",
      editView: "Edit View",
      job_des: "Dramatic, stylish, lush interior design",
      company_philosophy: "Company Philosophy",
      des1: "A graduate the Ecole Camondo in Paris with a degree in interior architecture and design, ROBERT COUTURIER moved to New York in 1981. He quickly established his own practice, which has been recognized in, among other media, Architectural Digest’s prestigious annual list of the best decorators and architectural firms in the world. Since December 2012, Elle Decor has included Mr. Couturier in its A-List Top 60 Designers and British House & Garden has named him one of their Top 10 Foreign Decorators.",

      des2: "In 1987 while still relatively inexperienced, Mr. Couturier was entrusted by the financier Sir James Goldsmith with what would amount to the single greatest private commission of modern times.",
      areas_expertise: "Areas of expertise",
      Vignettes: "• Vignettes •",
      flagship_project: "• flagship projects •",
      all_projects: "ALL PROJECTS",
      products: "• Products & Publications •",
      products_publications: "Products & Publications",
      report_card: "FRANKLIN REPORT CARD",
      connect_dot: "• connect •",
      products_des1:
        "At Home with Dogs and Their Designer: Sharing a Stylish Life",
      products_address: "Hardcover - October 3, 2017",
      instagram_des:
        "Link your Instagram account to your Franklin Report portfolio to automatically display a feed of your latest Instagram images.",
      authorize_instagram: "Authorize Instagram",
      edit_project: "EDIT ALL PROJECTS",
      edit_reorder_project: "EDIT/REORDER PROJECTS",
      add_project: "ADD PROJECTS",
      add_first_project: "Add first project",
      made_public: "Paid & Made Public: ",
      date: " December 1, 2020",
      connect: "Connect",
      cover_pic: "COVER IMAGE",
      company_snap: "COMPANY SNAPSHOT",
      company_head: "COMPANY HEADSHOT",
      add: "ADD",
      add_proj_title: "PROJECT",
      add_some: "ADD SOME",
      link_here: "LINK HERE",
      business_card: "BUSINESS CARD",
      business_helper: "This is the main company info; add other offices in",
      helper_settings: "Settings",
      company_logo: "COMPANY LOGO",
      please_add: "PLEASE ADD",
      vignette_no_projects:
        "ADD PROJECT IMAGES FIRST BELOW, THEN COME BACK TO CREATE YOUR VIGNETTES",
      public_preview: "PUBLIC PREVIEW",
      edit_view: "EDIT VIEW",
    },
  },
  product_publication: {
    titles: {
      product_publication: "• PRODUCTS & PUBLICATIONS •",
      product_publication_edit: "• EDIT PRODUCTS & PUBLICATIONS •",
      product_publication_required: "Product or Publication Title",
      product: "Product",
      products: "Products",
      publication: "Publication",
      publications: "Publications",
      subtitle: "Subtitle",
      subtitle_placeholder:
        "Feature product highlights and/or points of interest",
      product_url: "Product or Publication URL",
      product_url_placeholder: "Example: designsbooks.com/MyDesignBook",
      marketing_copy: "Marketing Copy ",
      marketing_copy_placeholder:
        "What distinguishes this item from other choices?",
      hashtags: "Hashtags ",
      hashtags_placeholder: "#InteriorDesign, #ReferenceBook ",
      upload_image: "Upload Image",
      save_item: "Save item",
      publish: "PUBLISH",
      Interior: "Interior Design Reference Specification Book",
      confirmation: "ARE YOU SURE YOU WANT TO DELETE THIS ITEM?",
      action: "You cannot undo this action",
      delete_item: "DELETE ITEM",
      update_image: "Update Image",
      errorTxtArea: "TMI: Please edit",
    },
  },
  vignettes: {
    titles: {
      add_vignettes:
        "Please add some projects so we can help you with your vignettes",
      add_project: "ADD PROJECTS",
      vignettes_des1: "Vignettes are small details of your project. ",
      vignettes_des2:
        "Add a minimum of four vignettes for this section to be displayed.",
      vignettes_drag:
        "Drag an image from your projects to the vignette boxes above. Next Step: Crop",
      compose_vignette: "• Compose your vignette •",
      vignette_link: "This vignette will link to:",
      vignette_radio1: "This Project Page",
      vignette_radio2: "Custom URL:",
      vignette_radio2_placeholder: "www.anysite.com",
      delete_vignette: "DELETE VIGNETTE FOR SURE?",
      drag_img: "Drag the crop selection to desired position. ",
      drag_zoom: "Move the zoom handle to change photo size.",
      save_continue: "Save & Continue",
    },
  },
  modal: {
    titles: {
      congrats: "CONGRATULATIONS!",
      email_success: "EMAIL SENT SUCCESFULLY",
      beautiful_portfolio:
        "Invite friends & clients to view your beautiful portfolio",
      invite_clients: "Let’s invite clients to view your new posting! ",
      email: "Email",
      send_invites: "SEND",
      make_happen: "MAKING IT HAPPEN",
      upadte_display: "UPDATE DISPLAY",
      make_public: "MAKE ALL PUBLIC",
      add_more: "Add more ",
      send_invites_btn: "SEND INVITES",
      portfolio_rate_card: "• PORTFOLIO RATE CARD •",
      portfolio_rate_card_des:
        "All portfolios for New Subscribers will be Free for the first 6 Months",
      regular_base:
        "Regular Base Price: $600 for annual subscription for first city.",
      unlimited:
        "Unlimited number of projects, products and publications included!",
      categories: "Up to four categories included in any city. ",
      additional_card: "Additional Cities",
      second_city: "2nd City- 10% discount, $540",
      third_city: "3rd City- 20% discount, $480",
      fourth_city: "4th+ Cities- 30% discount, $420",
      lower_base:
        "Lower Base Price of $450 to certain service categories, as less web traffic and/or lower business margins (plumbing, electricians, florists, roofers, housekeeping). Same discounts as above for multiple cities. ",
      ok: "OK",
      txtAreaPlaceholder: "Personal Note",
      inputplaceholder: "Emails separated by commas",
      delete_company: "ARE YOU SURE YOU WANT TO DELETE THIS COMPANY?",
      remove_person: "ARE YOU SURE YOU WANT TO REMOVE THIS PERSON?",
      cannot_undo: "You cannot undo this",
      your_feedback: "YOUR FEEDBACK",
      your_name: "Your Name",
      your_email: "Your Email",
      your_comments: "Your Comments",
      currently_signed:
        "You are currently signed in as the administrator, thus you cannnot deactivate/delete yourself.",
      contact_admin: "Please contact other admins to do so.",
      sure: "I AM SURE",
      got: "GOT IT",
      dispatch: "DISPATCH",
      upadte_password_1: "Your Password has been Updated",
      upadte_password_2: "Hopefully you wrote it down somewhere!",
      delete_multi_off:
        "This address is used in your public portfolios. Please choose a different address for those public portfolios, before you erase this address.",
      delete_off: "This address is used in your",
      delete_off_2:
        "portfolio. Please choose a different address for that portfolio before you can erase this address",
      delete_office: "ARE YOU SURE YOU WANT TO REMOVE THIS LOCATION?",
      invite_des:
        "Your  beautiful portfolio is now ready to share with the world",
    },
  },
  publicView_p: {
    titles: {
      Products_Publications: "Products & Publications",
      project_heading: "Featured Projects",
      productName: "Robert Couturier Inc.",
      products: "Products",
      publications: "Publications",
      products_1: "The Alexa Hampton Collection",
      products_1_subtext: "Furniture Line",
      more_info: "More Info",
      products_1_text:
        "Couturier’s name has become synonymous with continental and international style, and he is known for composing adventurous rooms that have a witty flair. ",
      hashtags: "#InteriorDesign",
      products_2_text:
        "A passion for luxury and beauty propels the multifaceted work of acclaimed international architect and interior designer Robert Couturier. Robert Couturier’s aesthetic is a dialogue between Old World elegance and contemporary design. His masterful approach effortlessly brings eras together, for example a Louis XVI commode with a 1960s lamp. Couturier’s name has become synonymous with continental and international style, and he is known for composing adventurous rooms that have a witty flair. All his interiors extol the importance of how a home should stimulate the five senses, from the tactile feel of upholstery to the visual presentation of objects that leads a person through a space.",
    },
  },
  passwords: {
    titles: {
      recover_password: "Recover password",
      email_pjn_Error: "Email or Phone Number not recognized",
      get_new_password: "GET NEW PASSWORD",
      Oops: "Oops! I can’t remember my email or phone number",
      recover_here: "Recover Here",
      Wait_password: "Wait, I remember my password!",
      call_me: "Please call us at 886.960.9100",
      call_me2: " if you cannot remember your email",
      sign_in: "Sign In",
      not_member: "Not a member?",
      join_here: "Join Here!",
      enter_new_password: "ENTER NEW PASSWORD",
      enter_password: "Enter Password ",
      set_new_password: "SET NEW PASSWORD",
      set: "You are all set!",
      ok: "ok!",
      find_account: "lets find your account",
      email_or_phone: "Enter Your Email or Phone Number",
      locate: "LOCATE",
      reset_password: "Reset your password",
      set_new_password_via: "Set new password via:",
      test: "Text",
      security_code: "Enter security code",
      text_message:
        "A text message with a 6-digit verification code was just sent to (917) 555-9100",
      get_code: "Didn’t get a code?",
      invalid_otp: "INVALID CODE",
      recover_credentials: "Recover credentials",
      recover_credentials1:
        "Your password reset link will be emailed to the address",
      recover_credentials2: "you used to created your Franklin Report Account",
      emai_username: "Enter Your Email",
      password_link: "SEND PASSWORD RESET LINK",
      password_link1: "Please call us at 886.960.9100",
      password_link2: "if you cannot remember your email",
      sent_to_email: "Your password reset Link has been sent to",
      great: "GREAT",
      notone: "Not that one...",
      we_dont1: "We don’t have ",
      we_dont2: " registered with the Franklin Report.",
      try_again: "PLEASE TRY AGAIN",
    },
  },
  settings: {
    titles: {
      heading_title: "My PERSONAL SETTINGS",
      settings: "My Settings",
      personal_settings: "My Personal Settings",
      basic_info: "Basic Info",
      first_name: "First Name: ",
      facebook: "Facebook : ",
      twitter: "Twitter :",
      instagram: "Instagram :",
      last_name: "Last Name: ",
      username: "Username:",
      primary_email: "Primary Email:",
      email: "Email:",
      phone: "Phone:",
      primaryEmail: "Primary Email:",
      mainPhone: "Main Phone:",
      erase: "Erase",
      save: "Save",
      ofc_nickename: "office nickname",
      main_phone: "Main Phone:",
      default_flipbook: "My Default Flipbook URL:",
      username_tip: "Not required, but helpful to submit reviews anonymously",
      phone_tip:
        "Your phone number is requested as we may need to contact you to authenticate job, review or comment details.",
      privacy_setting: "Privacy Settings",
      auth_options: "Authentication Options",
      mobile_auth:
        "We will text you to recover your username or email. This will go to",
      privacy_pref: "privacy preferences:",
      privacy_pref_desc:
        "Identity visibility for your Franklin Report reviews. ",
      real_name: "Real Name",
      show_city: "Show City",
      hide_city: "Hide City",
      password_cond: "8 characters. 1 Upper. 1 number or symbol",
      profile_pic_normal: "Upload Photo or Personal Emblem",
      fb_login_txt: "Use Facebook Photo",
      company_basics: "Company Basics",
      company_admin: "Company Administrators",
      distinguishing_characterstics: "Distinguishing characteristics",
      typical_job: "Typical Job Description/Company Bio",
      new_admin_contact: "New Admin contact, created with enhanced portfolio:",
      new_fr_contact: "FR Admin Contact: Ported from FR",
      add_company_profile: "Add Company",
      office_locations: "Office Locations",
      our_portfolio: "VIEW PORTFOLIOS",
      your_portfolio: "YOUR PORTFOLIOS",
      our_portfolio_settings: "VIEW PORTFOLIOS",
      new_portfolios: "+ New Portfolio",
      purchase_hist: "Portfolio History",
      password: "Password:",
      change_password: "Change Password:",
      enhanced_portfolios: "+ New Portfolio",
      new_enhanced_port: "NEW ENHANCED PORTFOLIOS",
      add_portfolios_new: "Add New Portfolio",
      add_portfolios_new_plus: "+ Add New Portfolio",
      portfolio_history: "Purchase History",
      tradeWorks_company: "TradeWorks Company profile",
      log_out: "Log Out",
      admins: "Admins:",
      admins_text: "Angeline Mathew Tim Saia ( You) Eliza Spear",
      portfolio_name: "Portfolio Name:",
      status: "Status:",
      where_service: "Where and what services do you offer?",
      addBtn: "add",
      which_office: "Which office will be listed in this portfolio?",
      paid_error: "You already paid for this market",
      which_market: "Which office listed as primary contact in this market?",
      view_edit: "View/Edit This Portfolio",
      make_all_public: "MAKE ALL PUBLIC",
      save_portfolio: "SAVE CHANGES",
      portfolio_com: "Portfolio Completion",
      business_card: "Business Card",
      Profile_Cover: "Profile & Cover Image",
      elevator_pitch: "Company Blurb",
      projects_summary: "Projects Summary & Images",
      products_publications: "Products & Publications",
      vignettes: "Vignettes",
      invited_clients: "Invited Clients to View",
      tally: "Tally:",
      active: "Active",
      to_bill: "Billing",
      principal_owner: "Principal or Owner Name(s)",
      public_contact_name: "Public Contact Name",
      primary_trade: "Primary Trade",
      secondary_trade: "Secondary Trade",
      more_trade: "More Trades?",
      your_establishment: "Year Established",
      employees: "# Employees",
      typical_cost: "Typical Cost of Project",
      clientele: "Clientele",
      venue: "Venue",
      your_venue: "Your Venue",
      licensed: "Licensed?",
      whom: "If yes, by whom?",
      yes: "Yes",
      no: "No",
      purchase_history: "PORTFOLIO PURCHASE HISTORY",
      purchase_history_sub:
        "Note: to cancel future autopayments, click Portfolio Visibility above to ONLY YOU status, before the payment is due. Call 866.990.9100 to update autopay",
      add_admin: "ADD NEW ADMIN",
      enable_des: "Please call the Franklin Report to enable this feature.",
      enable_num: "866.960.9100",
      enable_fun: "This will be an automatic function soon.",
      imonit: "I’m On It",
      go: "go",
      company_basics_label: "COMPANY SETTINGS",
      edit_personal_info: "EDIT PERSONAL INFORMATION",
      add_social: "CONNECT",
      company_email: "Company Email:",
      company_phone: "Company Phone:",
      add_office: "Add Office",
      location_des:
        "Any changes here will be reflected in your Franklin Report portfolio,",
      location_des1: "and your future TradeWorks company profile.",
      company_name: "company name",
      Headquarters: "Headquarters?",
      same_message: "Same message for all",
      billing_address: "Billing Address?",
      publicly_display: "Publicly Display?",
      ofc_nickname: "Office Nickname",
      display_where: "DISPLAYED WHERE?",
      services_offered: "SERVICES OFFERED?",
      services_provided: "Services Provided",
      public_error_1:
        "Please first unselect “Not Public” in the Markets dropdown to continue",
      public_error_2:
        "For this Office/Business to be displayed publicly, one market/service must be selcted.",
      market_ofc: "Markets this office serves?",
      userName_primary: "Username",
      contact_admin:
        "If you would like to no longer be an admin for this company, please ask another admin to erase you.",
      plus_company: "+ New Company",
      pitch_candidates: "PITCH TO POTENTIAL CANDIDATES",
      pitch_candidates_des:
        "Build your brand. Why is it exciting to work here?",
      company_snapshot: "Company Snapshot",
      company_snapshot_des:
        "Lead with industry keywords for better SEO searches",
      company_pitch: "Company Pitch",
      company_vibe: "Company Vibe",
      company_vibe_des:
        "We have limited you to 8 descriptors for greater impact and thoughfulness",
      college_allegiances: "College Allegiances",
      college_allegiances_des:
        "Check if you would like this publicly displayed; otherwise, will just be used to help sort the most promising candidates for you",
      edit_company: "Edit the Rest of Your company Profile",
      cover_photo: "Cover Photo/ Company Pitch",
      featured_office: "Featured Office Photos - 2",
      water_cooler: "Water Cooler - Employee Videos",
      our_offices: "Our Offices Shots",
      compny_culture: "Company Culture - Our Stories",
      current_team: "Current Team + Alumni",
      edit_company_des:
        "For other changes to the Company Profile, please go to that Company’s your ",
      edit_company_des_span1: "Company Basics ",
      edit_company_des_span2: "Company Profile Section",
      edit_company_des_con: "or the",
      default_search_city: "Default Search City:",
      add_more: "Add More",
    },
    messages: {},
    errors: {},
  },
  new_portfolio_popup: {
    titles: {
      leaving: "You are leaving ",
      creating: "& Creating a New Portfolio for",
      creating_new: "Create New Portfolio:",
      start_dupe: "Start with Dupe of",
      take_me: "Take me back to ",
      chicago_brutilism: "Chicago Brutilism ",
      portfolio: "Name this portfolio",
      lets_go: "LET’S GO",
      portfolio_name: "Name of Portfolio",
    },
    messages: {},
    errors: {},
  },
  moneyModal: {
    titles: {
      description1_case_a:
        "Current Franklin Report subscribers will receive a free upgrade to this enhanced portfolio ",
      description2_case_a1: "Your current subscription is paid through",
      description2_case_a2:
        "So the new, enhanced portfolio will appear instead of your legacy portfolio.",
      great: "GREAT!",
      description1_case_b1:
        "Your current Franklin Report portfolio subscription expired",
      description1_case_b3:
        "To display this new, enhanced portfolio, please pay the 25% renewal cost below (to keep the Franklin Report’s lights on!)",
      description1_case_b3_new: "Expiration date of one year after payment.",
      standard_annual: "Standard Annual Subscription:",
      currently_due: "Currently Due:",
      name_card: "Name on Card",
      card_num: "Card Number",
      expiration_date: "Exp Date ",
      zip_code: "Zip Code",
      cvv_code: "Security Code",
      or: "OR",
      renew: "Renew Subscription",
      go_public: "LET’S GO PUBLIC!",
      renew_rights1:
        "If you have any questions,  please email us at office@franklin.works or call 24/7 at 866.960.9100",
    },
  },
  invoice: {
    title: {
      billing_address: "BILLING ADDRESS",
      subcription: "PORTFOLIO SUBCRIPTION",
      membership: "Yearly Membership",
      invoice: "INVOICE",
      item: "Item/Market",
      description: "Description",
      qty: "Qty",
      unit_price: "Unit Price",
      total: "Total",
      due_date: "DUE DATE: ",
      thank_you: "THANK YOU",
      report: "THE FRANKLIN REPORT",
      pdf: "Save as PDF",
      print: "Print",
    },
  },
  portfolioMoney: {
    title: {
      expiration_date: "Expiration Date: ",
      payment_date: "Payment Date: ",
      new_market: "ADD NEW MARKET",
      add_market: "+ Add Market?",
      where_what: "Where and what services ",
      offered_market: "are offered in this New Market?",
      offered_port: "do you offer in this portfolio?",
      office_listed: "Which office listed as primary contact in this market?",
      add: "add",
      renewal: "Portfolio Renewal",
      expansion: "Portfolio Expansion",
      due: "Currently Due",
      due_months: "Due on",
      due_november: "Due February 1, 2022",
      card: "Card Number",
      name_card: "Name on Card",
      exp: "Exp Date ",
      zip: "Zip Code",
      security: "Security Code",
      save: "Save Info?",
      autorenew: "Autorenew?",
      check: "Check out now",
      or: "OR",
      additional_markets: "ADD ADDITIONAL MARKETS",
      expiry_year: "Expires one year after made public",
    },
  },
};

export default strings;
